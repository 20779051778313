<template>
    <BaseSection class="board section--wide section--extra-spacing">
        <template
            v-if="title"
            #title
        >
            <h2>{{ title }}</h2>
        </template>

        <section
            v-if="memberObject"
            class="board__section"
        >
            <BaseMember
                v-for="(memberItem, index) in member"
                :key="`member${index}`"
            >
                <template
                    v-if="memberItem.memberPicture && memberItem.memberPicture.length"
                    #picture
                >
                    <img
                        :src="memberItem.memberPicture[0].url"
                        alt=""
                        loading="lazy"
                        width="80"
                    >
                </template>

                <template #default>
                    <header>
                        <h5>{{ memberItem.memberTitle }} {{ memberItem.memberInitials }} {{ memberItem.memberLastName }} <template v-if="memberItem.memberCountryCode">({{ memberItem.memberCountryCode }})</template></h5>
                        <div
                            v-for="(type, typeIndex) in cleanMemberTypes"
                            :key="`member-type-${typeIndex}`"
                        >
                            {{ memberItem.memberType }}
                        </div>
                    </header>
                </template>
            </BaseMember>
        </section>
        <section>
            <BannerList
                v-if="offices && offices.length"
                :items="offices"
                :secondary-style="true"
                class="banner--extra-spacing"
            />
        </section>
    </BaseSection>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        required: false,
        default: 'Board'
    },
    member: {
        type: Array,
        required: false,
        default: null
    },
    offices: {
        type: Array,
        required: false,
        default: null
    }
});

const memberObject = computed(() => {
    return props.member && props.member.length ? props.member[0] : null;
});

const cleanMemberTypes = computed(() => {
    return memberObject.value && memberObject.value.memberType ? memberObject.value.memberType.split(' & ') : [];
});
</script>

<style lang="less">
.board__section {
    display: grid;
    gap: var(--spacing-lg);
    margin-top: var(--spacing-lg);

    @media @q-md-min {
        grid-template-columns: repeat(2, 1fr);
    }

    @media @q-lg-min {
        grid-template-columns: repeat(3, 1fr);
        margin-top: var(--spacing-xl);
        gap: var(--spacing-xl);
    }
}

.board[data-width='2']  .board__section {
    @media @q-lg-min {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>
