<template>
    <BaseQuicklinkSection
        v-if="linkLists.length"
        :lists="linkLists"
    />
</template>

<script setup>
const { uriFromEntryFields } = useUri();

const props = defineProps({
    lists: {
        type: Array,
        default: () => [],
        required: true
    }
});

const linkLists = computed(() => {
    return props.lists.map((list) => {
        return {
            title: list.title,
            links: list.links.map((link) => {
                return {
                    text: link.text,
                    uri: uriFromEntryFields(link)
                };
            })
        };
    });
});
</script>
