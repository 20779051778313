<template>
    <component
        :is="element"
        v-bind="attributes"
        class="learning-path-card"
    >
        <header
            v-if="!props.hideHeader"
            class="learning-path-card__header"
        >
            <slot name="header" />
        </header>

        <h4 class="learning-path-card__title">
            <slot name="title" />
        </h4>

        <div class="learning-path-card__text">
            <slot />
        </div>
    </component>
</template>

<script setup>
const { checkIsExternal } = useUri();

const props = defineProps({
    uri: {
        type: String,
        required: false,
        default: null
    },
    hideHeader: {
        type: Boolean,
        required: false,
        default: false
    }
});

const element = computed(() => {
    if (!props.uri) {
        return 'article';
    }
    if (checkIsExternal(props.uri)) {
        return 'a';
    }
    return resolveComponent('NuxtLink');
});

const attributes = computed(() => {
    if (!props.uri) {
        return null;
    }
    if (checkIsExternal(props.uri)) {
        return {
            href: props.uri,
            target: '_blank',
            rel: 'noopener noreferrer'
        };
    }
    return {
        to: props.uri
    };
});
</script>

<style lang="less">
.learning-path-card {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    --gap: .625rem;

    white-space: normal;
    width: 14.75rem;
    padding: var(--spacing-xl);

    background: var(--color-secondary-light);
    border-radius: var(--borderRadius-sm);
    text-decoration: none;
    color: var(--color-darkest);
    border: 1px solid transparent;
    transition: border-color var(--transition-fast);

    &[href]:focus {
        border-color: var(--color-secondary);
        overflow: visible;
    }

    > * + * {
        margin: var(--gap) 0;
    }

    @media @q-lg-min {
        width: 18.75rem;
    }
}

.learning-path-card__header {
    flex: 0 0 auto;
    margin-bottom: 1.5rem;

    .dn-icon {
        font-size: 4.375rem;
        transform: scale(1);
        transition: transform var(--transition-fast);

        .learning-path-card[href]:active &,
        .learning-path-card[href]:hover & {
            transform: scale(1.125);
        }
    }

    @media @q-lg-min {
        margin-bottom: 2rem;
    }
}

.learning-path-card__title {
    flex: 0 1 auto;
    margin: 0;
}

.learning-path-card__text {
    .typography-small;
    flex: 1 1 auto;

    opacity: .6;
    transition: opacity var(--transition-fast);

    .learning-path-card[href]:active &,
    .learning-path-card[href]:hover & {
        opacity: 1;
    }
}
</style>
