<template>
    <BaseSection v-if="platforms">
        <template #title>
            <h2>{{ title }}</h2>
        </template>

        <template
            v-if="subtitle"
            #subtitle
        >
            <p>{{ subtitle }}</p>
        </template>

        <BaseEducationalPlatforms :platforms="platforms" />
    </BaseSection>
</template>

<script setup>
import {computed, defineProps} from 'vue';

const { mapCmsIcon } = useCmsIcon();

const props = defineProps({
    title: {
        type: String,
        required: false,
        default: 'Platforms',
    },
    subtitle: {
        type: String,
        required: false,
        default: null,
    },
    externalPlatforms: {
        type: Array,
        required: true,
        default: () => [],
    },
});

const platforms = computed(() => {
    return props.externalPlatforms.map((platform) => {
        return {
            title: platform.title,
            icon: mapCmsIcon(platform.icon),
            url: platform.url,
        };
    });
});
</script>
