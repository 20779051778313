<template>
    <BaseSection v-if="guidelines.length || pending">
        <template #title>
            <h2>Guidelines</h2>
        </template>

        <BaseGuidelineCards
            :cards="guidelineCards"
            :is-loading="pending"
        />
    </BaseSection>
</template>

<script setup>
import guidelinesByTopicsQuery from '~/graphql/queries/guidelines/byTopic.graphql';

const { variables } = useCraftGraphql();

const response = await useLazyAsyncQuery({
    query: guidelinesByTopicsQuery,
    variables: {
        ...variables,
        slug: useRoute().params.slug,
    },
});

const { data: entry, pending } = response;

const guidelines = computed(() => {
    if (!entry.value?.guidelines) {
        return [];
    }

    return entry.value.guidelines;
});

const guidelineCards = computed(() => {
    return guidelines.value.map((guideline) => {
        return {
            title: guideline.title,
            url: '/' + guideline.uri,
        };
    });
});
</script>
