<template>
    <BaseSection class="section--wide">
        <template #title>
            <h2 :id="kebabCase(title)">{{ title }}</h2>
        </template>

        <template
            v-if="subtitle"
            #subtitle
        >
            <p>{{ subtitle }}</p>
        </template>

        <template #action>
            <template v-if="eventType === 'live'">
                <nuxt-link
                    v-if="
                        route.name === 'topics-slug' &&
                            route.params &&
                            route.params.slug
                    "
                    :to="'/education-events/events?topic=' + route.params.slug"
                    aria-label="See all events"
                >
                    See all <BaseIcon icon="chevron-right" />
                </nuxt-link>
                <nuxt-link
                    v-else
                    to="/education-events/events"
                    aria-label="See all events"
                >
                    See all <BaseIcon icon="chevron-right" />
                </nuxt-link>
            </template>
            <template v-else>
                <nuxt-link
                    v-if="
                        route.name === 'topics-slug' &&
                            route.params &&
                            route.params.slug
                    "
                    :to="
                        '/education-events/education?topic=' +
                            route.params.slug
                    "
                    aria-label="See all on-demand education"
                >
                    See all <BaseIcon icon="chevron-right" />
                </nuxt-link>
                <nuxt-link
                    v-else
                    to="/education-events/education"
                    aria-label="See all on-demand education"
                >
                    See all <BaseIcon icon="chevron-right" />
                </nuxt-link>
            </template>
        </template>

        <ClientOnly>
            <BaseCardCollection
                v-if="cards && cards.length"
                :cards="cards"
            />
            <p v-else>Sorry, there are no items to show here right now.</p>
        </ClientOnly>
    </BaseSection>
</template>

<script setup lang="ts">
import {kebabCase} from 'lodash-es';
import {startOfDay} from 'date-fns';

import RelatedLiveEventsQuery from '~/graphql/queries/events/relatedLive.graphql';
import RelatedOnDemandEventsQuery from '~/graphql/queries/events/relatedOnDemand.graphql';
import useSrcSets from '~/utils/srcset';

const { getSrcSet } = useSrcSets();
const { activeSite } = useMultisite();
const route = useRoute();

type Event = {
    id: string;
    title: string;
    uri: string;
    dateRange: {
        start: string;
        end: string;
    };
    image: {
        large: string;
        medium: string;
        small: string;
    }[];
};
type Props = {
    eventType: 'live' | 'on-demand';
    title: string;
    subtitle: string | null;
    events: Event[];
};
const props = defineProps<Props>();

const futureEvents = ref<Event[]>([]);

function isFuture(event: Event) {
    return event.dateRange?.start >= startOfDay(new Date()).toISOString();
}

function isOngoing(event: Event) {
    return (
        event.dateRange?.start < startOfDay(new Date()).toISOString() &&
        event.dateRange?.end >= startOfDay(new Date()).toISOString()
    );
}

function filterFutureEvents() {
    futureEvents.value = props.events.filter((event) => {
        return isFuture(event) || isOngoing(event);
    });
}

const cardLimit = computed(() => {
    if (useRoute().name === 'related-content') {
        return 4;
    } else {
        return 3;
    }
});


const hasEvents = (potentialEventsArr: Event[]) => potentialEventsArr && potentialEventsArr.length;

watch(
    [props.events, activeSite],
    async function() {
        filterFutureEvents();

        let query = RelatedLiveEventsQuery;

        const cardLimitValue = cardLimit.value ?? 3;
        // If less than 3 or 4 events were passed, get the remaining amount here
        if (!props.events || props.events.length < cardLimit.value) {

            const limit = futureEvents.value.length
                ? cardLimit.value - futureEvents.value.length
                : cardLimit.value;

            // Exclude news items already added manually
            const excludeIds = futureEvents.value
                ? futureEvents.value.map(({ id }) => id)
                : [];

            const variables: {
                limit?: number;
                exclude?: string[];
                site?: string;
                relatedToEntries?: { slug: string | string[]; type: string }[];
            } = {
                limit,
                exclude: ['not', ...excludeIds],
                site: activeSite.value?.handle ?? undefined,
            };

            // On specific pages, show only related items.
            if (useRoute().name === 'topics-slug') {
                variables.relatedToEntries = [
                    { slug: useRoute().params.slug, type: 'topic' },
                ];
            }

            query = props.eventType === 'live' ?
                RelatedLiveEventsQuery :
                RelatedOnDemandEventsQuery;

            const result = await useAsyncQuery({
                query,
                variables,
            });

            // Todo remove generic any output
            const data = result?.data;

            const proppedEvents = props.events || [];
            if(hasEvents(proppedEvents)) {
                futureEvents.value = [...proppedEvents].slice(0, cardLimitValue);
            }

            if (!data) {
                return;
            }


            if (hasEvents(data?.value?.ongoing)) {
                futureEvents.value = [
                    ...futureEvents.value,
                    ...data.value.ongoing
                ].slice(0, cardLimitValue);
            }

            // Append any future events as needed
            if (hasEvents(data?.value?.future)) {
                const remaining = cardLimitValue - futureEvents.value.length;

                futureEvents.value = [
                    ...futureEvents.value,
                    ...data.value.future.slice(0, remaining)
                ].slice(0, cardLimitValue);
            }
        } else {
            // Max events selected, so we fill future events with selected events.
            futureEvents.value = props.events;
        }
    },
    { immediate: true }
);

const { makeMeta, makeLocation } = useEventsCard();
const { getCardPills } = usePillsCard();

const cards = computed(() => {
    if (futureEvents.value) {
        return futureEvents.value.map((event) => {
            let image = null;
            if (event.image.length) {
                image = {
                    src: event.image?.[0]?.large,
                    srcset: getSrcSet(event.image?.[0], 'default'),
                };
            }
            return {
                image,
                title: event.title,
                meta: makeMeta(event),
                location: makeLocation(event),
                uri: event.uri,
                pills: getCardPills(event),
            };
        });
    }
    return [];
});
</script>
