<template>
    <BaseSection>
        <template #title>
            <h2 v-show="title">{{ title }}</h2>
        </template>
        <BaseEndorsements :items="items" />
    </BaseSection>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        items: {
            type: Array,
            required: false,
            default: null,
        },
    },
};
</script>
