<template>
    <transition name="fade">
        <div
            v-if="mappedSections.length"
            class="flexible-sections"
            :class="{ 'flexible-sections--wide': fullWidth }"
            v-bind="$attrs"
        >
            <component
                :is="map[section.__typename]"
                v-for="(section, index) in mappedSections"
                :key="`section-${index}`"
                :lists="lists"
                class="flexible-sections__section"
                v-bind="getProps(section)"
            />
        </div>

        <content-placeholders
            v-else-if="placeholder"
            class="flexible-sections"
        >
            <template v-if="placeholder === 'content'">
                <BaseSection>
                    <template #default>
                        <content-placeholders-img />
                    </template>
                </BaseSection>

                <BaseSection>
                    <template #title>
                        <content-placeholders-heading />
                    </template>

                    <template #default>
                        <content-placeholders-text :lines="10" />
                    </template>
                </BaseSection>

                <BaseSection
                    v-for="i in [1, 2, 3]"
                    :key="i"
                >
                    <template #title>
                        <content-placeholders-heading />
                    </template>

                    <template #default>
                        <content-placeholders-text />
                    </template>
                </BaseSection>
            </template>

            <template v-if="placeholder === 'overview'">
                <BaseSection>
                    <template #default>
                        <content-placeholders-img />
                    </template>
                </BaseSection>

                <BaseSection>
                    <template #title>
                        <content-placeholders-heading />
                    </template>

                    <template #default>
                        <content-placeholders-text :lines="4" />
                    </template>
                </BaseSection>

                <BaseSection>
                    <template #default>
                        <content-placeholders-img />
                    </template>
                </BaseSection>

                <BaseSection
                    v-for="i in [1, 2, 3]"
                    :key="i"
                >
                    <template #title>
                        <content-placeholders-heading />
                    </template>

                    <template #default>
                        <content-placeholders-text :lines="2" />
                    </template>
                </BaseSection>

                <BaseSection>
                    <template #default>
                        <content-placeholders-img />
                    </template>
                </BaseSection>
            </template>
        </content-placeholders>
    </transition>
</template>

<script setup>
const props = defineProps({
    placeholder: {
        type: String,
        required: false,
        default: null,
    },
    sections: {
        type: Array,
        required: true,
        default: () => [],
    },
    members: {
        type: Array,
        required: false,
        default: () => {
            return [];
        },
    },
    lists: {
        type: Array,
        default: () => [],
        // Todo make required and provide in all instances
        required: false,
    },
    fullWidth: {
        type: Boolean,
        required: false,
        default: false,
    },
    accordionPlus: {
        type: Boolean,
        required: false,
        default: false,
    }
});

/* eslint-disable camelcase */
const map = {
    // Flexible
    flexibleSection_richText_BlockType: resolveComponent(
        'FlexibleSectionsRichText'
    ),
    flexibleSection_accordionItem_BlockType: resolveComponent(
        'FlexibleSectionsRichText'
    ),
    flexibleSection_button_BlockType: resolveComponent(
        'FlexibleSectionsButton'
    ),
    flexibleSection_cards_BlockType: resolveComponent('FlexibleSectionsCards'),
    flexibleSection_quote_BlockType: resolveComponent('FlexibleSectionsQuote'),
    flexibleSection_embed_BlockType: resolveComponent('FlexibleSectionsEmbed'),
    flexibleSection_image_BlockType: resolveComponent('FlexibleSectionsImages'),
    flexibleSection_warning_BlockType: resolveComponent(
        'FlexibleSectionsWarning'
    ),
    flexibleSection_accordion_BlockType: resolveComponent(
        'FlexibleSectionsAccordion'
    ),
    flexibleSection_endorsements_BlockType: resolveComponent(
        'FlexibleSectionsEndorsements'
    ),
    flexibleSection_board_BlockType: resolveComponent('FlexibleSectionsBoard'),
    flexibleSection_imageCTA_BlockType: resolveComponent(
        'FlexibleSectionsTextImage'
    ),
    flexibleSection_boardOverview_BlockType: resolveComponent(
        'FlexibleSectionsBoardOverview'
    ),
    flexibleSection_video_BlockType: resolveComponent('FlexibleSectionsVideo'),
    flexibleSection_accordionPlus_BlockType: resolveComponent(
        'FlexibleSectionsAccordionPlus'
    ),
    // Overview
    overviewFlexibleSections_sections_BlockType: resolveComponent(
        'FlexibleSectionsSections'
    ),
    overviewFlexibleSections_educationalPlatforms_BlockType: resolveComponent(
        'FlexibleSectionsEducationalPlatforms'
    ),
    overviewFlexibleSections_news_BlockType: resolveComponent(
        'FlexibleSectionsNews'
    ),
    overviewFlexibleSections_events_BlockType: resolveComponent(
        'FlexibleSectionsEvents'
    ),
    overviewFlexibleSections_imageCTA_BlockType: resolveComponent(
        'FlexibleSectionsTextImage'
    ),
    overviewFlexibleSections_linkLists_BlockType: resolveComponent(
        'FlexibleSectionsLinkLists'
    ),
    overviewFlexibleSections_learningPaths_BlockType: resolveComponent(
        'FlexibleSectionsLearningPaths'
    ),
    overviewFlexibleSections_guidelines_BlockType: resolveComponent(
        'FlexibleSectionsGuidelines'
    ),
    overviewFlexibleSections_books_BlockType: resolveComponent(
        'FlexibleSectionsBooks'
    ),
    overviewFlexibleSections_cards_BlockType: resolveComponent(
        'FlexibleSectionsCards'
    ),

    //  Block
    flexibleSection_chatBot_BlockType: resolveComponent('FlexibleSectionsBlock'),
    flexibleSection_contentModule_BlockType: resolveComponent(
        'FlexibleSectionsBlock'
    ),

    // Guidelines
    guidelinesRelatedContent_news_BlockType: resolveComponent(
        'FlexibleSectionsNews'
    ),
    guidelinesRelatedContent_imageCTA_BlockType: resolveComponent(
        'FlexibleSectionsTextImage'
    ),
    guidelinesRelatedContent_events_BlockType: resolveComponent(
        'FlexibleSectionsEvents'
    ),
};
/* eslint-enable camelcase */

const shouldRender = (section) => {
    if (!section) {
        return false;
    }

    return !(!section.__typename || !map[section.__typename]);
};


const shouldBeAccordionPlus = (section) => {
    if(props.accordionPlus) {
        return true;
    }
    if(!section || section.__typename !== 'flexibleSection_accordion_BlockType') {
        return false;
    }
    return [...new Set(section.items.map((item) => item.__typename))].length > 1;
};

const mappedSections = computed(() => toValue(props.sections)
    .filter((section) => shouldRender(section))
    .map((section) => {
        switch (section.__typename){
        case 'flexibleSection_accordion_BlockType':
            return shouldBeAccordionPlus(section) ? ({
                ...section,
                __typename: 'flexibleSection_accordionPlus_BlockType',
            }) : section;
        default:
            return section;
        }
    }));

const getProps = (section) => {
    const _props = { ...section };
    if (section.__typename === 'flexibleSection_boardOverview_BlockType') {
        return {
            ..._props,
            boardMembers: props.members,
        };
    }
    return _props;
};
</script>

<style lang="less">
.flexible-sections:not(.flexible-sections--wide) {
    .grid-container(true, var(--grid-maxWidth-content));
}

.flexible-sections[data-wide] {
    .grid-container(true, var(--grid-maxWidth-page));

    .section__title h2 {
        .typography-h3;
    }
}

.flexible-sections--wide {
    --spacing-section: var(--spacing-sm);
    max-width: 100%;
}
</style>
