<template>
    <BaseSection
        v-if="block"
        section-width="content"
    >
        <div class="block">
            <img
                v-if="block.image && block.image.length"
                :src="block.image[0].url"
                alt=""
                class="card__img"
            >

            <h4
                v-if="block.title"
                class="card__title"
            >
                {{ block.title }}
            </h4>
            <!-- eslint-disable vue/no-v-html -->
            <div
                v-if="block.description"
                class="card__description"
                v-html="block.description"
            />
            <!-- eslint-enable vue/no-v-html -->
            <BaseInput
                v-if="block.blockActionType === 'inputField'"
                :placeholder="block.caption"
                class="card__action"
            />
            <BaseButton
                v-else-if="block.blockActionType === 'button'"
                :to="block.internalLink ? block.internalLink.uri : block.externalLink"
                class="card__action"
            >
                {{ block.action }}
            </BaseButton>
            <p
                v-if="block.caption"
                class="card__caption"
            >
                {{ block.caption }}
            </p>
        </div>
    </BaseSection>
</template>

<script setup lang="ts">
type Props = {
    block: {
        image?: {
            url: string;
        }[];
        title?: string;
        description?: string;
        action?: string;
        blockActionType?: string;
        caption?: string;
        internalLink?: {
            uri: string;
        };
        externalLink?: string;
    }[]
}

const props = defineProps<Props>();

const block = computed(() => props.block?.[0]);
</script>

<style lang="less" scoped>
.block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 23px 24px;
    position: relative;
    gap: 18px;

    width: 100%;
    border-radius: 6px;

    & > * {
        text-align: center;
        z-index: 1;
    }

    p {
        width: 100%;
    }

    .dn-input {
        border-radius: 6px;
    }



    &:before {
        background: var(--color-alt-light, var(--color-primary-light));
        content: '';
        inset: 0;
        position: absolute;
        border-radius: 6px;
        z-index: 0;
        opacity: 1;
    }
}

@media @q-lg-min {
    .card p,
    .card__description{
        max-width: 600px;
        margin: 0 auto;
    }

    .dn-input {
        width: 330px;
    }

}


.card__caption {
    margin-top: 0;
    font-size: 1em;
    opacity: 0.4;
}
</style>
