<template>
    <div class="educational-platforms">
        <BasePlatform
            v-for="(platform, index) in platforms"
            :key="index"
            :url="platform.url"
            class="educational-platforms__platform"
        >
            <template #title>
                {{ platform.title }}
            </template>

            <template #icon>
                <BaseIcon
                    :icon="platform.icon"
                    :auto-color="false"
                />
            </template>
        </BasePlatform>
    </div>
</template>

<script>
export default {
    props: {
        platforms: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
};
</script>

<style lang="less">
.educational-platforms {
    .card-grid();
}
</style>
