<template>
    <div class="learning-paths">
        <BaseSlider
            v-if="items.length"
            :items="items"
            :controls-enabled="true"
            class="learning-paths__wrapper"
        >
            <template v-slot:slide="{ item }">
                <BaseCardLearningPath
                    :key="item.uri"
                    :item="item"
                    :uri="item.uri"
                    :hide-header="!items.filter((i) => i.icon)?.length"
                >
                    <template #header>
                        <BaseIcon
                            :icon="item.icon"
                            :auto-color="false"
                        />
                    </template>
                    <template #title>{{ item.title }}</template>
                    <template #default>{{ item.text }}</template>
                </BaseCardLearningPath>
            </template>
        </BaseSlider>
    </div>
</template>

<script setup>
const { mapCmsIcon } = useCmsIcon();
const { uriFromEntryFields } = useUri();

const props = defineProps({
    learningPaths: {
        type: Array,
        required: true,
        default: () => []
    }
});

const items = computed(() => {
    return props.learningPaths.map((learningPath) => {
        return {
            title: learningPath.title,
            text: learningPath.text,
            icon: mapCmsIcon(learningPath.icon),
            uri: uriFromEntryFields(learningPath)
        };
    });
});
</script>

<style lang="less">
.flexible-sections *.learning-paths {
    grid-column: -1/1 !important; /* stylelint-disable-line declaration-no-important */ // bleed
    position: relative;
    margin-top: var(--spacing-section);
}

.learning-paths__control {
    display: none;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 50%;
    width: 3.75rem;
    height: 3.75rem;
    font-size: 2.5rem;

    transform: translateY(-50%);

    border: 0;
    background: var(--color-darkest);
    color: var(--color-lightest);
    border-radius: var(--borderRadius-full);
    cursor: pointer;

    z-index: 10;

    &[data-left] {
        left: ~'max(2rem, calc(50% - var(--grid-maxWidth-page) / 2))';
    }

    &[data-right] {
        right: ~'max(2rem, calc(50% - var(--grid-maxWidth-page) / 2))';
    }

    .learning-paths:hover & {
        display: flex;
    }
}
</style>
