<template>
    <BaseSection>
        <template #title>
            <component
                :is="headerTextSize"
                v-if="headerText"
            >
                {{ headerText }}
            </component>
        </template>

        <template #default>
            <!-- eslint-disable vue/no-v-html -->
            <div
                data-allow-anchor
                v-html="richText"
            />
            <!-- eslint-enable vue/no-v-html -->
        </template>
    </BaseSection>
</template>

<script>
export default {
    props: {
        headerText: {
            type: String,
            default: null,
            required: false
        },
        headerTextSize: {
            type: String,
            default: null,
            required: false
        },
        richText: {
            type: String,
            default: null,
            required: true
        }
    }
};
</script>
