<template>
    <BaseSection v-if="linkAttrs">
        <BaseButton
            :element="linkAttrs.to ? NuxtLink : 'a'"
            v-bind="linkAttrs"
        >
            {{ blockButtonTitle }}
        </BaseButton>
    </BaseSection>
</template>

<script setup>
const props = defineProps({
    blockButtonUrl: {
        type: String,
        required: false,
        default: null,
    },
    blockButtonInternalUrl: {
        type: Array,
        required: false,
        default: null,
    },
    blockButtonFile: {
        type: Array,
        required: false,
        default: null,
    },
    blockButtonMailTo: {
        type: String,
        required: false,
        default: null,
    },
    blockButtonTitle: {
        type: String,
        required: true,
        default: '',
    },
});

const { checkIsExternal } = useUri();

const NuxtLink = resolveComponent('NuxtLink');

const isExternal = computed(() => {
    return checkIsExternal(props.blockButtonUrl);
});

const linkAttrs = computed(() => {
    if (props.blockButtonInternalUrl && props.blockButtonInternalUrl.length) {
        return {
            to: `/${props.blockButtonInternalUrl[0]?.uri}`,
        };
    }

    if (props.blockButtonFile && props.blockButtonFile.length) {
        return {
            href: props.blockButtonFile[0]?.url,
            target: 'blank',
            rel: 'noopener noreferrer',
        };
    }

    if (props.blockButtonMailTo) {
        return {
            href: 'mailto:' + props.blockButtonMailTo,
            rel: 'noopener noreferrer',
        };
    }

    if (isExternal.value) {
        return {
            href: props.blockButtonUrl,
            target: 'blank',
            rel: 'noopener noreferrer',
        };
    } else if (props.blockButtonUrl) {
        return {
            to: props.blockButtonUrl,
        };
    }

    return null;
});
</script>
