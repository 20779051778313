<template>
    <BaseSection>
        <BaseVideo
            :video-url="videoUrl"
            :video-caption="props.caption"
        />
    </BaseSection>
</template>

<script setup lang="ts">
type Props = {
    video: {
        url: string;
    }[];
    caption?: string;
}

const props = defineProps<Props>();

const videoUrl = computed(() => props.video?.[0]?.url);
</script>
