<template>
    <BaseSection
        class="section--larger-gap section--extra-spacing section--text-image section--wide"
        :sidebar-position="imageAlignment ? imageAlignment : 'right'"
    >
        <template #title><h2 :id="kebabCase(title)">{{ title }}</h2></template>

        <template #default>
            <!-- eslint-disable vue/no-v-html -->
            <div
                data-allow-anchor
                v-html="description"
            />
            <!-- eslint-enable vue/no-v-html -->
        </template>

        <template
            v-if="buttonTitle"
            #footer
        >
            <BaseButton
                class="uro-button--mobileWide"
                :element="!isExternal ? NuxtLink : 'a'"
                v-bind="linkAttrs"
            >
                {{ buttonTitle }}
            </BaseButton>
        </template>

        <template
            v-if="image && image.length"
            #aside
        >
            <a
                v-if="allowImageEnlargement"
                :href="image[0].url"
                target="_blank"
                rel="noopener"
                aria-label="Click to enlarge"
                title="Click to enlarge"
            >
                <BaseFigure
                    :src="image[0].large"
                    alt=""
                    :srcset="getSrcSet(image[0],'textImage')"
                    sizes="(min-width: 1401px) 605px, (min-width: 993px) 430px, 100vw"
                />
            </a>
            <BaseFigure
                v-else
                :src="image[0].large"
                alt=""
                :srcset="getSrcSet(image[0],'textImage')"
                sizes="(min-width: 1401px) 605px, (min-width: 993px) 430px, 100vw"
            />
        </template>
    </BaseSection>
</template>

<script setup>
const NuxtLink = resolveComponent('NuxtLink');

import {kebabCase} from 'lodash-es';
import useSrcSets from '~/utils/srcset';

const { getSrcSet } = useSrcSets();


const props = defineProps({
    title: {
        type: String,
        default: null,
        required: true
    },
    description: {
        type: String,
        default: null,
        required: false
    },
    buttonTitle: {
        type: String,
        default: null,
        required: false
    },
    buttonUrl: {
        type: String,
        default: null,
        required: false
    },
    image: {
        type: Array,
        default: null,
        required: true
    },
    imageAlignment: {
        type: String,
        default: 'right',
        required: false
    },
    allowImageEnlargement: {
        type: Boolean,
        default: false,
        required: false
    }
});

const { checkIsExternal } = useUri();

const isExternal = computed(() => {
    return checkIsExternal(props.buttonUrl);
});

const linkAttrs = computed(() => {
    if (isExternal.value) {
        return {
            href: props.buttonUrl,
            target: 'blank',
            rel: 'noopener noreferrer'
        };
    } else if (props.buttonUrl) {
        return {
            to: props.buttonUrl
        };
    }

    return null;
});
</script>
