<template>
    <DnAccordion
        :id="slugify(title).toLowerCase()"
        ref="accordion"
        :items="[items]"
        :scroll-into-view="false"
        :scroll-smooth="true"
        class="base-accordion"
        :open-indexes="allOpen ? [0] : []"
    >
        <template #title>
            <h4>{{ title }}</h4>
        </template>


        <template #default>
            <FlexibleSections
                :sections="Array.isArray(items) ? items : []"
                :placeholder="pending ? 'overview' : undefined"
                :full-width="true"
            />
        </template>

        <template #icon>
            <BaseIcon
                icon="chevron-down"
                :style="{
                    opacity: allOpen ? 0.5 : 1,
                }"
            />
        </template>
    </DnAccordion>
</template>

<script setup lang="ts">
import DnAccordion from '@digitalnatives/accordion';
import BaseIcon from '~/components/BaseIcon/BaseIcon.vue';
import {useAccordionStore} from '~/store/accordionStore';
import slugify from 'slugify';

const accordion = useAccordionStore();
const { allOpen } = storeToRefs(accordion);

type Props = {
    title: string,
    items: Array<any>,
};

defineProps<Props>();
</script>
