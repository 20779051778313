<template>
    <BaseSection v-if="image && image.length">
        <BaseFigure
            :src="image[0].intermediateUrl"
            responsive
            :srcset="getSrcSet(image[0], 'responsive')"
            width="844"
            sizes="100vw"
            alt=""
        >
            <template #caption>{{ caption }}</template>
        </BaseFigure>
    </BaseSection>
</template>

<script setup>
import useSrcSets from '~/utils/srcset';

const { getSrcSet } = useSrcSets();

defineProps({
    caption: {
        type: String,
        default: null,
        required: false,
    },
    image: {
        type: Array,
        default: null,
        required: true,
    },
});
</script>
