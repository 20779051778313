<template>
    <a
        :href="url"
        target="blank"
        rel="noopener"
        class="uro-endorsement"
    >
        <div>
            <BaseFigure
                v-if="image && image.length"
                :src="image[0].intermediateUrl"
                :srcset="getSrcSet(image[0], 'responsive')"
                width="844"
                sizes="100vw"
                alt=""
            />
            <span>{{ title }}</span>
        </div>
    </a>
</template>

<script setup>
import useSrcSets from '~/utils/srcset';

const { getSrcSet } = useSrcSets();

defineProps({
    title: {
        type: String,
        default: null,
        required: false,
    },
    image: {
        type: Array,
        default: null,
        required: true,
    },
    url: {
        type: [Object, String],
        default: null,
        required: false,
    },
});
</script>

<style lang="less" src="./BaseEndorsement.less" />
