<template>
    <BaseSection>
        <BaseBox>
            <template #title>
                <template v-if="title">
                    {{ title }}
                </template>
            </template>

            <template #default>
                <!-- eslint-disable vue/no-v-html -->
                <div
                    data-allow-anchor
                    v-html="richText"
                />
                <!-- eslint-enable vue/no-v-html -->
            </template>
        </BaseBox>
    </BaseSection>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: 'Warning',
        },
        richText: {
            type: String,
            required: false,
            default: null,
        },
    },
};
</script>
