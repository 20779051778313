import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAccordionStore = defineStore('accordion', () => {

    const allOpen = ref(false);
    const open = ref<string[]>([]);

    const toggle = (id: string) => {
        const index = open.value.indexOf(id);
        if (index > -1) {
            open.value.splice(index, 1);
        } else {
            open.value.push(id);
        }
    };

    const toggleAll = (newState?: boolean) => {
        allOpen.value = typeof newState === 'boolean' ? newState : !allOpen.value;
    };

    const isOpen = (id: string) => {
        return open.value.includes(id) || allOpen.value;
    };

    return {
        open,
        allOpen,
        toggle,
        toggleAll,
        isOpen,
    };
});
