<template>
    <section class="box">
        <div
            v-if="$slots.title"
            class="box__header"
        >
            <h4 class="box__header__title"><slot name="title" /></h4>
        </div>
        <div class="box__content">
            <slot />
        </div>
    </section>
</template>

<script>
export default {};
</script>

<style lang="less" src="./BaseBox.less" />
