<template>
    <a
        :href="url"
        target="blank"
        rel="noopener noreferrer"
        class="platform"
    >
        <div class="platform__icon">
            <slot name="icon" />
        </div>

        <h3 class="platform__title">
            <slot name="title" />
        </h3>

        <p class="platform__instruction">
            <slot name="instruction">
                Go to platform <BaseIcon icon="arrow-up-right" />
            </slot>
        </p>
    </a>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            required: true,
            default: '',
        },
    },
};
</script>

<style lang="less">
.platform {
    display: flex;
    flex-direction: column;

    background: var(--color-gray-200);
    border-radius: var(--borderRadius-xs);
    color: var(--color-darkest);
    padding: var(--spacing-lg);
    text-decoration: none;

    transition: background-color var(--transition-fast);

    &:hover,
    &:active {
        background-color: var(--color-secondary-light);
    }

    &:focus {
        box-shadow: var(--boxShadow-outline);
    }

    &:active {
        background-color: var(--color-secondary);
    }
}

.platform__icon {
    flex: 0 0 auto;
    font-size: 4rem;

    .dn-icon {
        transition: transform var(--transition-fast);

        .platform:hover &,
        .platform:focus &,
        .platform:active & {
            transform: scale(1.125);
        }
    }
}

.platform__title {
    margin: 0;
    transition: color var(--transition-fast);
}

.platform__instruction {
    display: flex;
    align-items: center;
    margin: 0;

    .dn-icon {
        font-size: 1.25em;
        margin-left: 0.25rem;
    }
}
</style>
