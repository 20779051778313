<template>
    <BaseSection>
        <template #title>
            <h2>Programmes</h2>
        </template>

        <BaseGuidelineCards
            :cards="booksCards"
            :is-loading="pending"
        />
    </BaseSection>
</template>

<script setup>
import booksByTopicsQuery from '~/graphql/queries/books/byTopic.graphql';

const { variables } = useCraftVariables();

const response = await useLazyAsyncQuery({
    query: booksByTopicsQuery,
    variables: {
        ...variables,
        slug: useRoute().params.slug,
    },
});

const { craftEntry: books, pending } = useCraftPage(response, 'books', false);

const booksCards = computed(() => {
    return books.value?.map((book) => {
        return {
            title: book.title,
            url: '/' + book.uri,
        };
    });
});
</script>
